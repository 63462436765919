<template>
  <div>
    <v-row>
      <v-col cols="12">
        <select-pos-model v-model="id_pos" :label="$t('labels.pos')" dense outlined clearable hide-details
          @change="focusBasketInput"></select-pos-model>
      </v-col>

      <v-col cols="12">
        <input-qr-scan-model v-model.number="basket_code" class="c-input-small" type="text" ref="basketCodeRef"
          :label="$t('labels.basket_code')" dense outlined clearable hide-details
          @keyupEnter="getReceiptBasket"></input-qr-scan-model>
      </v-col>

      <v-col cols="12">
        <v-btn color="success" block :disabled="isDisabledBtn" @click="createCommand">{{ $t('labels.confirm') }}</v-btn>
      </v-col>

      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">#</th>
                <th role="columnheader" class="text-center">{{ $t('labels.sku') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.basket') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in scanned_baskets" :key="`se_${item.id}`" class="text-center">
                <td>{{ key + 1 }}</td>
                <td>{{ item.goods_sku }}</td>
                <td>{{ item.basket_code }}</td>
              </tr>
              <tr v-for="(item, key) in scanning_baskets" :key="`si_${item.id}`" class="text-center error--text">
                <td>{{ key + 1 + scanned_baskets.length }}</td>
                <td>{{ item.goods_sku }}</td>
                <td>{{ item.basket_code }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12" v-if="waiting_baskets.length > 0" class="mt-10">
        <v-simple-table>
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">{{ $t('labels.basket') }} <span class="error--text">({{
                  waiting_baskets.length }})</span></th>
                <th role="columnheader" class="text-center">{{ $t('labels.sku') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.quantity') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.status') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.employee_receipt_1') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.employee_stowing') }}</th>
                <th role="columnheader" class="text-center">{{ $t('labels.position') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in waiting_baskets" :key="item.id" class="text-center">
                <td>{{ item.basket_barcode }}</td>
                <td>{{ item.goods_sku }}</td>
                <td>{{ item.net_quantity }}</td>
                <td>{{ item.status === 1 ? $t('labels.waiting_stowing') : $t('labels.process_stowing') }}</td>
                <td>{{ item.employee_last_handle_name || item.employee_last_handle_full_name }}</td>
                <td>{{ item.employee_stowing_name || item.employee_stowing_full_name }}</td>
                <td>
                  <div v-html="getStorageLocation(item.pos_storage_location)"></div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ReceiptReceiveBasket",
  data: () => ({
    basket_code: null,
    isLoading: false,
    poss: [],
    id_pos: null,
    waiting_baskets: [],
    scanned_baskets: [],
    scanning_baskets: [],
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    isDisabledBtn() {
      return this.scanned_baskets.length === 0 || this.scanning_baskets.length > 0
    }
  },
  methods: {
    getStorageLocation(locations) {
      if (!locations) {
        return ''
      }
      const locationSplit = locations.split(';')
      return locationSplit.join('<br>')
    },
    async getReceiptBasket() {
      if (!this.basket_code) {
        return false
      }

      const checkBasket = this.scanned_baskets.find(c => c.basket_code == this.basket_code)
      if (checkBasket) {
        this.$root.$emit('playErrorAudio')
        this.$vToastify.error(this.$t('messages.scanned_basket_code', { code: this.basket_code }))
        this.basket_code = null
        return false
      }

      if (this.scanning_baskets.length > 0) {
        const checkScanningBasket = this.scanning_baskets.find(c => c.basket_code == this.basket_code)
        if (!checkScanningBasket) {
          const sBaskets = this.scanning_baskets.map(sb => (sb.basket_code))
          this.$root.$emit('playErrorAudio')
          this.$vToastify.error(this.$t('messages.scan_basket_same_tracking', { codes: sBaskets.join(', ') }))
          this.basket_code = null
          return false
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/receipt-get-basket-for-process-by-history', {
          basket_code: this.basket_code,
          id_pos: this.id_pos
        })
        this.scanned_baskets.push({ ...data.item })
        const scanning_baskets = [...data.items].filter(i => !this.scanned_baskets.find(j => j.id === i.id))
        this.scanning_baskets = [...scanning_baskets]
        this.isLoading = false
        this.basket_code = null
        this.$root.$emit('playSuccessAudio')
      } catch (e) {
        this.basket_code = null
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.$root.$emit('playErrorAudio')
      }

    },

    focusBasketInput() {
      this.waiting_baskets = []
      if (!this.id_pos) {
        return false
      }
      this.$nextTick(() => {
        this.$refs.basketCodeRef.focusInput()
        this.getReceiptBasketWaiting()
      })
    },
    async createCommand() {
      if (this.isDisabledBtn) {
        this.$vToastify.error(this.$t('messages.can_not_process_stowing'));
        return false
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/receipt-create-stowing-command', {
          scanned_baskets: this.scanned_baskets,
          id_pos: this.id_pos
        })
        this.$emit('submit', data)
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
      } catch (e) {
        this.basket_code = null
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.$root.$emit('playErrorAudio')
      }
    },
    async getReceiptBasketWaiting() {
      const { data } = await httpClient.post('/receipt-list-waiting-stowing', {
        id_pos: this.id_pos
      })
      this.waiting_baskets = [...data]
      this.scanning_baskets = []
      this.scanned_baskets = []
    },
  },
  mounted() {

  }
}
</script>

<style scoped></style>
